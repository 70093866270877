/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
// import headerStyles from "./Header.module.css";
import WebStyle from "../../Common.css";
import WebResponsiveStyle from "../../Commonresponsive.css";
import { IoSearch } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { loginCheck, setCookies } from "../../utilities/auth";
import axios from "../../utilities/axios";
import { api_url } from "../../utilities/constants";
import Context from "../../context_api/global";
import LiveChat from "../LiveChat";
import { API_HOST_URL } from "../../config";
import { pxy_url } from "../../utilities/constants";
import { api_url_main as API_URL } from "../../utilities/constants";
import $ from "jquery";
import { Button, Navbar, NavDropdown, Container, Row, Col, Dropdown} from 'react-bootstrap'
//import { propTypes } from "react-bootstrap/esm/Image";

export default function Header(props) {
  const [menus, setMenuData] = useState([]);
  const [show, setShow] = useState();
  const [carts, setCartData] = useState();
  // const [curPlan, setCurPlan] = useState([]);
  // const [searchedData, setSearchData] = useState([]);
  const [isloggedin, setLoginStatus] = useState(false);
  const router = useHistory();
  const { search_text, handletextChange } = useContext(Context);
  const path = window.location.pathname;
  const { userData } = useContext(Context);
  // console.log('path -> ', path)
  useEffect(async () => {
    const fetchCategory = async () => {
      setLoginStatus(await loginCheck());
      // const fetchCategory = async () => {
      //   const response = await axios.get(`${api_url}/content/category/`);
      //   const data = await response.data;
      //   setMenuData(data);
      //   setLoginStatus(await loginCheck());
    };
    fetchCategory();

    // const carturl = `${pxy_url + API_URL}/cart-data`;
    // let user_id = localStorage.getItem("user");
    // let databody = {user_id:user_id};

    // const cartresponse = await fetch(carturl, {
    //   method: "POST",
    //   headers: {
    //     "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin" : "*",
    //   },
    //   body: JSON.stringify(databody),
    // });

    // const cartresponseJson = await cartresponse.json();
    // console.log(cartresponseJson);
    // setCartData(cartresponseJson);

  }, []);

  const getSearchData = async (val) => {
    handletextChange(val);
    router.push(`/search`);
  };
  const handleLogout = async () => {
    localStorage.removeItem("loginToken");
    localStorage.removeItem("user");
    localStorage.removeItem("user_email");
    localStorage.removeItem("name");
    localStorage.removeItem("phone_no");
    setCookies("");
    router.push("/login");
  }
  return (
    <>
      <LiveChat />
      <header class="header">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 header_top">
              <div class="mobile_hamburger">
                <button type="button" class="hamburger_menu" onclick="openNav()">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
              <div class="padding_horizontal logo">
                <Link to="/"><img
                  src={require("../../assets/img/new_logo.png")}
                  alt="logo"
                />
                </Link>
              </div>
              <div class="padding_horizontal header_search">
                <form action>
                  <div class="col-lg-12">
                    <div class="form-group search_top">
                      <input
                        list="data"
                        type="search"
                        value={search_text}
                        onClick={() => {
                          router.push(`/search`);
                        }}
                        onChange={(e) => getSearchData(e.target.value)}
                        id="search"
                        placeholder="Search Products"
                        autoFocus={props.focus ? true : false}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div class="padding_horizontal header_top_right">
                <button type="button" class="btn mobile_header_search">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
                <ul>
                  <li>
                    {isloggedin ? (
                      // <Link onClick={handleLogout} ><i class="fa-solid fa-sign-out"></i></Link>
                      <Link to="/myprofile" ><i class="fa-solid fa-user"></i></Link>
                    ) :
                      (
                        <Link to="/login"><i class="fa-solid fa-user"></i></Link>
                      )}
                  </li>

                  {isloggedin ? (
                    // <Link onClick={handleLogout} ><i class="fa-solid fa-sign-out"></i></Link>
                    <li><Link to="/myprofile" ><i class="fa-solid fa-bell"></i></Link></li>
                  ) : ''}



                  <li><a href="javascript:void(0);"><i class="fa-solid fa-heart"></i></a></li>
                  <li><Link to="/cart"><i class="fa-solid fa-cart-shopping"></i></Link>
                    {/* { carts.data.length >= 1 ? (
              <span class="cart-count">{ carts.data.length }</span>
              ): ( '' )}  */}
                  </li>
                </ul>
              </div>
            </div>
            {/* New Menu Start */}
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Deal Zone"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Top Deals
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                          48 Hour Weekend Deals
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                          Clearance Sale
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Daily Deals
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Stunning Deals
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        UL's Choice Top 50
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Best Buys Under 2500
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Best Buys Under 5K
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Best Buys Under 10K
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Super Combos
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        All Products on Sale
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        All Decor on Sale
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Sofas & Recliners"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Sofas
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        All Sofas
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Fabric Sofa Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Wooden Sofa Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Leather Sofa Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Leatherette Sofa Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        L Shaped Sofa Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Diwans
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Loveseats
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Divider className="d-md-none" />
                  </Col>

                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Recliners
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        All Recliners
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        1 Seater Recliners
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        2 Seater Recliners
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        3 Seater Recliners
                        </a>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Fabric Recliners
                        </a>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Leatherette Recliners
                        </a>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Motorized Recliners
                        </a>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Manual Recliners
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>

                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Sofa Bed
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        All Sofa Beds
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Fabric Sofa Beds
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Wooden Sofa Beds
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Futons
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Seating & Chairs
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Lounge Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Accent Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Ottomans & Stools
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bean Bags
                        </a>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Benches
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bar Stools
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Rocking Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Gaming Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Living"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Seating & Chairs
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Lounge Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Accent Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Recliners
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Sofa Cum Bed
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Ottomans & Stools
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bean Bags
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Benches
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bar Stools
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Divider className="d-md-none" />
                  </Col>

                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Tables
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Coffee Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Side & End Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Console Table
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Nested Tables
                        </a>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Coffee Table Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>

                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Living Storage
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        TV Units
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bookshelves
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Shoe Racks
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Prayer Units
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Showcases
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Wall Shelves
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Corner Storage
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Room Divider
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Living Room Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Bedroom & Mattresses"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Beds
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Beds with Storage
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Beds without Storage
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        King Size Beds
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Queen Size Beds
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Single Beds
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Double Beds
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bed-Mattress Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Upholstered Beds
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Divider className="d-md-none" />
                  </Col>

                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Mattresses
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        All Mattresses
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        King Size Mattress
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Queen Size Mattress
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Single Bed Mattress
                        </a>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Double Bed Mattress
                        </a>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Mattress By Material
                        </a>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Mattress by Brand
                        </a>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Mattress By Range
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>

                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Pillows
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Storage & Accessories
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Wardrobes
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bedside Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Chest of Drawers
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Kids Room
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Kids Beds
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Kids Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bunk Beds
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Kids Seating
                        </a>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Kids Decor
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Dining"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Dining Tables & Chairs
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        6 Seater Dining Table Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        4 Seater Dining Table Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        2 & 3 Seater Dining Table Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        8 Seater Dining Table Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        All Dining Table Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Dining Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Dining Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Dining Benches
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Divider className="d-md-none" />
                  </Col>

                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Storage & Bar Furniture
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Crockery Units
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Kitchen Cabinets & Racks
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bar Stools
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bar Cabinets
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Storage"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Living Storage
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Shoe Racks
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bookshelves
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Showcases
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Prayer Units
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        TV Units
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Wall Shelves
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Entryway & Foyer
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Living Room Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Divider className="d-md-none" />
                  </Col>

                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Bedroom Storage
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Cupboards
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Chest of Drawers
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bedside Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Dressers & Mirrors
                        </a>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Storage Chests
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>

                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Dining Storage
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Crockery Units
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Kitchen Cabinets & Racks
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bar Cabinets
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Kids Storage
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Kids Bookshelves
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Kids Bedside Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Kids Storage Cabinets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Kids Chest of Drawers
                        </a>
                      </Link>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Kids Wardrobes
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Study"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Study Tables
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Study Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Computer Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Office Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Solid Wood Study Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Laptop Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Study Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Study Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Study Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Divider className="d-md-none" />
                  </Col>

                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Storage
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Bookshelves
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Wall Shelves
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>

                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"building"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Decor
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Study Lamps
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Outdoor"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Balcony & Outdoor
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Balcony Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Balcony Sets
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Swing Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Outdoor Tables
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Hammocks
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Plastic Chairs
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
            <NavDropdown
              className="pr-2 py-2 align-text-top"
              title="Interiors"
              id="basic-nav-dropdown"
            >
              <Container className="eventsNav pt-0 mt-0">
                <Row>
                  <Col xs="12" md="6" className="text-left">
                    <Dropdown.Header>
                      {/* <FontAwesomeIcon
                        color="black"
                        icon={"concierge-bell"}
                        size="1x"
                        className="pr-1"
                      /> */}
                      {"  "}
                      Urban Interiors
                    </Dropdown.Header>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Full Home Interiors
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Modular Kitchens
                        </a>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link href="/">
                        <a className="nav-link" role="button">
                        Modular Wardrobes
                        </a>
                      </Link>
                    </Dropdown.Item>
                  </Col>
                </Row>
              </Container>
            </NavDropdown>
          </div>
        </div>
      </header>

      {/* <div class="clearfix"></div> */}
    </>
  );
}
