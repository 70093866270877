/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import plansStyles from "../styles/plans.module.css";
import { is_logged_in } from "../utilities/auth";
import axios from "../utilities/axios";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";
import styled from "styled-components";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { postRequest } from "../utilities/ApiCall";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_HOST_URL } from "../config";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";
import Swal from "sweetalert2";

// const Cryptr = require('cryptr');
// const cryptr = new Cryptr('my');

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const Cart = (props) => {
  const [loading, setLoading] = useState(true);
  const [carts, setCartData] = useState([]);
  const router = useHistory();

  useEffect(async () => {
    setLoading(true);
    const url = `${pxy_url + API_URL}/cart-data`;
    let user_id = localStorage.getItem("user");
    let data = {user_id:user_id};

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin" : "*",
      },
      body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    // console.log(responseJson);
    setCartData(responseJson);
    setLoading(false);
  }, []);
  const removeCart = async () => {
    setLoading(true);
    const url = `${pxy_url + API_URL}/add-to-cart`;
    let paramss = props.match.params;
    let user_id = localStorage.getItem("user");
    // let quantity = this.state.value;
    // console.log(quantity);
    let data = { user_id:user_id};

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin" : "*",
      },
      body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    // console.log(responseJson);
    if (responseJson.status === 1) {
        
        setLoading(false);
        Swal.fire({
          title: "Product Added To Cart!",
          icon: "success",
          confirmButtonText: "OK!",
        });
        setTimeout(() => {
            router.push("/");
        }, 2000);
      // Device Binding Checking end
    } else {
        setLoading(false);
      // console.log("logged in failed", response.status, responseJson);

      Swal.fire({
        title: `Error!`,
        text: `Please try after sometime`,
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
    setLoading(false);
}
  if (loading) {
    return (
      <Override>
        <Loader type="Bars" color="red" height={70} width={70} />
      </Override>
    );
  } else {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Prelito : Cart</title>
        </Helmet>
        <Header />
        <div class="clearfix"></div>
	
                <section class="section cart_details_sec">
                    <div class="container">
                        <div class="row">
                            <div class="inner_page_main_part cart_page">
                                <div class="cart_page_left">
                                    <div class="product_box_cart_sec">
                                    {carts.data.map((crt, index) => (
                                        <div class="product_box_cart">
                                            <div class="product_box_cart_top">
                                                <div class="product_box_cart_top_left">
                                                    <img src={ carts.imagePath + crt.product_image} />
                                                </div>
                                                <div class="product_box_cart_top_right">
                                                    <div class="product_box_cart_top_right_one">
                                                        <h3>{crt.name}</h3>
                                                        <span>Product Category: {crt.product_category_name}</span>
                                                    </div>
                                                    <div class="product_box_cart_top_right_bottom">
                                                        <div class="product_box_cart_top_right_bottom_left">
                                                            <span onClick='decreaseCount(event, this)'>-</span>
                                                            <input type="text" class="product_quantity_field" value="1"/>
                                                            <span onClick='increaseCount(event, this)'>+</span>
                                                        </div>
                                                        <div class="product_box_cart_top_right_bottom_right">
                                                            <span>&#8360; {crt.special_price}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="product_box_cart_bottom">
                                                <div class="product_box_cart_bottom_part">
                                                    <a href="javascript:void(0);">
                                                        <i class="fa-solid fa-trash-can"></i>
                                                        <span>Remove</span>
                                                    </a>
                                                </div>
                                                <div class="product_box_cart_bottom_part move_to_wishlist">
                                                    <a href="javascript:void(0);">
                                                        <i class="fa-solid fa-heart"></i>
                                                        <span>move to wishlist</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                                <div class="cart_page_right">
                                    <div class="product_box_cart_order">
                                        <h2>order summary</h2>
                                        <div class="add_discount_code">
                                            <a href="javascript:void(0);">
                                                <i class="fa-light fa-plus"></i>
                                                <span>Add a discount code</span>
                                            </a>
                                        </div>
                                        <div class="cart_totals">
                                            <div class="totals_item">
                                            <label>Subtotal</label>
                                            <span>&#8360; {carts.subtotal}</span>
                                            </div>
                                            <div class="totals_item">
                                            <label>Estimated Shipping</label>
                                            <span>&#8360; 0</span>
                                            </div>
                                            <div class="totals_item">
                                            <label>Estimated Tax</label>
                                            <span>&#8360; 0</span>
                                            </div>
                                            <div class="totals_item total_amout">
                                            <label>Total</label>
                                            <span>&#8360; {carts.subtotal}</span>
                                            </div>
                                        </div>
                                        <div class="checkout_button">
                                        <Link to="checkout"><button type="button" class="proceed_checkout"  >Proceed to checkout</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="clearfix"></div>
        
        <Footer />
      </div>
    );
  }
};

export default Cart;
