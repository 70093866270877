/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import signinStyles from "../styles/login.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FcGoogle } from "react-icons/fc";
import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { Link, Redirect, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "../utilities/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { object } from "prop-types";
import { useRadioGroup } from "@material-ui/core";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import FacebookLogin from 'react-facebook-login';
import { setCookies } from "../utilities/auth";
import styled from "styled-components";
import Header from "../components/Header/Header";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { AiFillEyeInvisible } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";
import Swal from "sweetalert2";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";

const Override = styled.div`
  margin-top: -10px;
`;

export default function Login(props) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState("");
  const [usernameError, setUsernameError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [redirectUrl, setRedirectUrl] = useState("/");
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [googleLoading, setGoogleLoading] = useState(false);
  const [facebookLoading, setFacebookLoading] = useState(false);

  let history = useHistory();
  useEffect(() => {
    try {
      if (props.location.state) {
        setRedirectUrl(props.location.state.from.pathname);
      } else {
        // console.log("props user came direct");
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const responseGoogle = async (response) => {
    console.log('Coming Soon');
    // // setGoogleLoading()
    // // console.log("google response", JSON.stringify(response));
    // setError("");
    // try {
    //   let google_token = "";
    //   if (response.tokenId) google_token = response.tokenId;

    //   const body = {
    //     auth_token: google_token,
    //   };
    //   const res = await fetch(`${api_url}/auth/google/`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(body),
    //   });
    //   const data = await res.json();
    //   // console.log(data);
    //   if (data.detail) {
    //     setError(data.detail);
    //     // Swal.fire({
    //     //     title: 'Login!',
    //     //     text: `${data.detail && ''}`,
    //     //     icon: 'info',
    //     //     confirmButtonText: 'Try Again'
    //     // })
    //   }
    //   if (data.tokens) {
    //     localStorage.setItem("loginToken", JSON.stringify(data["tokens"]));
    //     localStorage.setItem("user", data["id"]);
    //     localStorage.setItem("user_email", data["email"]);
    //     setCookies(await JSON.stringify(data["tokens"]));
    //     setError("");

    //     window.location.reload();
    //   }
    //   setGoogleLoading(false);
    // } catch (error) {
    //   console.log("ERROR ->", error);
    //   setGoogleLoading(false);
    // }

    // // console.log(data);
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    const url = `${pxy_url + API_URL}/login`;
    
    setError("");
    let data = { username: username.toLowerCase(), password: password };
    setLoader(true);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin" : "*",
      },
      body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    // console.log(responseJson);
    if (responseJson.status === 1) {
        console.log("logged in successfully", response.status, responseJson);
        localStorage.setItem(
          "loginToken",
          JSON.stringify(responseJson.data.id)
        );
        localStorage.setItem("user", responseJson.data.id);
        localStorage.setItem("user_email", responseJson.data.email);
        localStorage.setItem("name", responseJson.data.name);
        localStorage.setItem("phone_no", responseJson.data.phone_no);
        setCookies(await JSON.stringify(responseJson.data.id));
        setLoader(false);
        // window.location.reload();
        history.push("/");
        Swal.fire({
          title: "Login Success!",
          icon: "success",
          confirmButtonText: "Explore Prelito!",
        });
        setTimeout(() => {
          setError("");
        }, 4000);
      // Device Binding Checking end
    } else {
      setLoader(false);
      // console.log("logged in failed", response.status, responseJson);

      Swal.fire({
        title: `${responseJson.errors}`,
        text: `${responseJson.errors && ""}`,
        icon: "error",
        confirmButtonText: "Try Again",
      });
      setError(responseJson.detail);
      responseJson.username?.length > 0
        ? setUsernameError(responseJson.username[0])
        : setUsernameError("");
      responseJson.password?.length > 0
        ? setPasswordError(responseJson.password[0])
        : setPasswordError("");
    }
  };

  const isAuthenticated = localStorage.getItem("loginToken");
  if (isAuthenticated) {
    // console.log("authenticated");
    return <Redirect to={redirectUrl} />;
  } else {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Prelito : Login</title>
        </Helmet>
        <Header />
        <div className={signinStyles.signin}>
          <div className={signinStyles.signin__bg} />
          <div className={signinStyles.signin__container}>
            <div className={signinStyles.signin__shadow}>
              <form onSubmit={(e) => handleLogin(e)} method="post">
                <h1 className={signinStyles.signin__title}>Sign In</h1>
                <p
                  style={{ textAlign: "center" }}
                  className={signinStyles.btnerrorMessage}
                >
                  {error}
                </p>
                <div className={signinStyles.group}>
                  <input
                    className={signinStyles.form_input}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="E-mail"
                    required={true}
                  />
                  <label className={signinStyles.form_input_label}>
                    Email
                  </label>
                </div>
                <p className={signinStyles.errorMessage}> {usernameError} </p>

                <div className={signinStyles.group}>
                  <input
                    className={signinStyles.form_input}
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="* * * * * *"
                    required
                  />

                  <label className={signinStyles.form_input_label}>
                    Password
                  </label>
                  {showPassword ? (
                    <Visibility
                      onClick={() => setShowPassword(!showPassword)}
                      className={signinStyles.rahil}
                    />
                  ) : (
                    <VisibilityOff
                      onClick={() => setShowPassword(!showPassword)}
                      className={signinStyles.rahil}
                    />
                  )}
                </div>
                <p className={signinStyles.errorMessage}> {passwordError} </p>
                <div className={signinStyles.signin__btn_container}>
                  <div className={signinStyles.signin__btn}>
                    {!loader ? (
                      <input
                        type="submit"
                        className={signinStyles.custom_button}
                        value="Sign In"
                      />
                    ) : (
                      <button className={signinStyles.custom_button}>
                        <Override>
                          <Loader
                            type="ThreeDots"
                            color="white"
                            height={60}
                            width={60}
                          />
                        </Override>
                      </button>
                    )}
                  </div>
                </div>
              </form>
              <div className={signinStyles.signin__btn_container}>
                <div className={signinStyles.signin__btn}>
                  {/* <p className={signinStyles.btnerrorMessage}>{error}</p> */}
                  <GoogleLogin
                    clientId="163036482960-82de4ravfon3kuktl09cl365ojdgh1la.apps.googleusercontent.com"
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        className={signinStyles.google}
                      >
                        <FcGoogle
                          className={signinStyles.signin__google_icon}
                        />

                        {googleLoading ? "Loading..." : "Signin With Google"}
                      </button>
                    )}
                    onSuccess={responseGoogle}
                    onRequest={() => setGoogleLoading(true)}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                </div>
              </div>
              <div>
                <p
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: "12px",
                  }}
                >
                  By logging in, you agree to our{" "}
                  <Link to="terms-of-use">Terms of Use</Link> and{" "}
                  <Link to="privacy-policy">Privacy Policy</Link>
                </p>
              </div>
              <div className={signinStyles.signin__option}>
                <span className={signinStyles.signin__option_newuser}>
                  Not on Prelito yet?
                </span>
                <Link to="/signup" className={signinStyles.signin__option_link}>
                  Sign up now.
                </Link>
              </div>
              <div
                className={signinStyles.user_forgot}
                style={{ marginTop: "20px" }}
              >
                <Link
                  to="/forgot-password"
                  className={signinStyles.forgot_password}
                >
                  Forgot Password?
                </Link>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    );
  }
}
