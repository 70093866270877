/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import plansStyles from "../styles/plans.module.css";
import axios from "../utilities/axios";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";
import styled from "styled-components";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { postRequest } from "../utilities/ApiCall";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_HOST_URL } from "../config";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";

// const Cryptr = require('cryptr');
// const cryptr = new Cryptr('my');

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const Photos = (props) => {
    const [loading, setLoading] = useState(true);
    const [photos, setPhotos] = useState([]);
    const router = useHistory();

    useEffect(async () => {
        setLoading(true);
        const url = `${pxy_url + API_URL}/allphotos`;
        let data = { limit: -1 };

        const response = await fetch(url, {
            method: "POST",
            headers: {
                // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
                "Content-Type": "application/json",
                // "Access-Control-Allow-Origin" : "*",
            },
            body: JSON.stringify(data),
        });

        const responseJson = await response.json();
        // console.log(responseJson.data);
        setPhotos(responseJson);
        setLoading(false);

        // console.log(act_prd)
    }, []);

    if (loading) {
        return (
            <Override>
                <Loader type="Bars" color="red" height={70} width={70} />
            </Override>
        );
    } else {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Prelito : Photos</title>
                </Helmet>
                <Header />
                <div class="clearfix"></div>

                <article class="inner_banner">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <h1>1,00,000 Home design photos</h1>
                                <p>Kitchen, Barh, Bedroom, living, Dining, Outdoor & Garden, Baby & Kids, Home office, Home Bar, Gym, Staircase</p>
                            </div>
                        </div>
                    </div>
                </article>

                <div class="clearfix"></div>

                <section class="section product_type photos_page">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 product_type_heading">
                                <div class="sort_by">
                                    <p><span>Sort by: <b>Popular Today <i class="fa fa-caret-down"></i></b></span> <span><b>1-80</b> of <b>2,53,90,866</b></span> Photos</p>
                                </div>
                            </div>
                            {photos.data.map((type, index) => (
                            <div class="col-lg-3 product_part">
                                <div class="product_type_image">
                                <img
                                src={ photos.imagePath + type.image}
                                />
                                </div>
                                <div class="photos_name">
                                    <div class="photos_name_icon">
                                        <img src={require("../assets/img/photgrapher.webp")}/>
                                    </div>
                                    <span>Studios lotus</span>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                </section>

                <div class="clearfix"></div>

                <Footer />
            </div>
        );
    }
};

export default Photos;
