import { pxy_url } from "./constants";
import { api_url_main as API_URL } from "./constants";
import { getToken } from "./auth";

export const getRequest = async (url, token = false) => {
  // console.log(`${API_URL}${url}`);
  var accessToken = "";
  if (token) {
    accessToken = await getToken();
    if (accessToken) accessToken = JSON.parse(accessToken).access;
  }
  const response = await fetch(`${pxy_url + API_URL}${url}`, {
    headers: {
      "content-type": "application/json",
      Authorization: token ? `Bearer ${accessToken}` : null,
    },
  }).catch((err) => console.log(err));
  return response;
};

export const postRequest = async (url, reqBody, reqMethod, token = false) => {
  // console.log(`${API_URL}${url}`);
  var accessToken = "";
  if (token) {
    accessToken = await getToken();
    if (accessToken) accessToken = JSON.parse(accessToken).access;
  }
  const response = await fetch(`${pxy_url + API_URL}${url}`, {
    headers: {
      // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
      "Content-Type": "application/json",
      // "Access-Control-Allow-Origin" : "*",
    },
    method: "POST",
    body: reqBody,
  }).catch((err) => console.log(err));
  return response;
};
