/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import plansStyles from "../styles/plans.module.css";
import axios from "../utilities/axios";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";
import styled from "styled-components";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { postRequest } from "../utilities/ApiCall";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_HOST_URL } from "../config";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";
import { setCookies } from "../utilities/auth";
// const Cryptr = require('cryptr');
// const cryptr = new Cryptr('my');

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const Myorders = (props) => {
    const [loading, setLoading] = useState(true);
    const [blogs, setBrands] = useState([]);
    const [act_prd, setActProduct] = useState([]);
    const router = useHistory();

    useEffect(async () => {
        setLoading(true);
        let body = { limit: -1 };
        // Brands
        const brandres = await fetch(`${pxy_url + API_URL}/brands`, {
            method: "POST",
            // mode: "no-cors",
            headers: {
                // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
                "Content-Type": "application/json",
                // "Access-Control-Allow-Origin" : "*",
            },
            body: JSON.stringify(body),
        });
        const brands = await brandres.json();
        setBrands(await brands);
        setLoading(false);

        // console.log(act_prd)
    }, []);
    const handleLogout = async () => {
        localStorage.removeItem("loginToken");
        localStorage.removeItem("user");
        localStorage.removeItem("user_email");
        localStorage.removeItem("name");
        localStorage.removeItem("phone_no");
        setCookies("");
        router.push("/login");
    }
    if (loading) {
        return (
            <Override>
                <Loader type="Bars" color="red" height={70} width={70} />
            </Override>
        );
    } else {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Prelito : MyProfile</title>
                </Helmet>
                <Header />
                <div class="clearfix"></div>

                <section class="section my_account_page">
                    <div class="container">
                        <div class="col-lg-12 my_account_heading">
                            <h3>My account</h3>
                        </div>
                        <div class="col-lg-12">
                            <div class="my_account_main_part">
                                <div class="my_account_left_part">
                                    <ul>
                                        <li class="active"><Link to="/myprofile" >Overview</Link></li>
                                        <li><Link to="/myorders" >Orders & returns</Link></li>
                                        {/* <li><a href="coupons.html">Coupons & offers</a></li> */}
                                        {/* <li><a href="profile.html">Profile</a></li> */}
                                        <li><Link to="/myaddresses" >Address</Link></li>
                                    </ul>
                                </div>
                                <div class="my_account_right_part">
                                    <div class="my_account_right_part_one">
                                        <div class="my_account_right_part_one_left">
                                            <div class="account_profile_image">
                                            <img src={require("../assets/img/user.png")}/>
                                            </div>
                                            <div class="account_profile_details">
                                                <p>Hi</p>
                                                <h4>{localStorage.getItem("name")}</h4>
                                                <h5>{localStorage.getItem("user_email")}</h5>
                                                <h5>{localStorage.getItem("phone_no")}</h5>
                                            </div>
                                        </div>
                                        <div class="my_account_right_part_one_right">
                                            <ul>
                                                <li><a href="javascript:void(0);">My Ideabook</a></li>
                                                <li><a href="javascript:void(0);">My Orders</a></li>
                                                <li><a href="javascript:void(0);">My wishlist</a></li>
                                                <li><Link onClick={handleLogout} >Logout</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="my_account_right_part_two">
                                        <div class="prodcut_wishlist_box">
                                            <div class="similar_product_list_heading">
                                                <span>My Wishlist</span>
                                                <h5>Hi! You have no recent orders.</h5>
                                                <ul>
                                                    <li><Link to="/">Return to home</Link></li>
                                                </ul>
                                            </div>
                                            {/* <ul>
                                                <li><a href="javascript:void(0);"><img src="images/product-1.png"></a></li>
                                                <li><a href="javascript:void(0);"><img src="images/product-1.png"></a></li>
                                                <li><a href="javascript:void(0);"><img src="images/product-1.png"></a></li>
                                                <li><a href="javascript:void(0);"><img src="images/product-1.png"></a></li>
                                            </ul> */}
                                        </div>
                                        <div class="refer_earn_box">
                                            <div class="refer_earn_box_content">
                                                <h3>Refer and Earn</h3>
                                                <p>Invite your friends to try Prelito. They get instant $50 off. You win $ 50 once they take a product.</p>
                                            </div>
                                            <div class="refer_earn_box_image">
                                            <img src={require("../assets/img/refer.png")}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="my_account_right_part_three">
                                        <span>My Orders</span>
                                        <h5>Hi! You have no recent orders.</h5>
                                        <ul>
                                            <li><Link to="/">Return to home</Link></li>
                                        </ul>
                                    </div>
                                    <div class="my_account_right_part_four">
                                        <div class="my_account_right_part_four_content">
                                            <h3>Register as professional</h3>
                                            <p>Get listed in Prelito and access millions of customers to promote your products and services.</p>
                                        </div>
                                        <div class="my_account_right_part_four_listed_link">
                                            <a href="javascript:void(0);">Get listed now!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="clearfix"></div>

                <Footer />
            </div>
        );
    }
};

export default Myorders;
