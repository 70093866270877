/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from "react";
import { useEffect, useState, useContext } from "react";
import ReactGa from "react-ga";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Context from "../context_api/global";
import SearchCard from "../components/Slider/SearchCard";
import searchStyles from "../styles/search.module.css";
import { getRequest } from "../utilities/ApiCall";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";

const Search = () => {
  // const { searchdata, search_text, setRefine, fetchData } = useContext(Context);
  const { search_text } = useContext(Context);
  // console.log('search text')
  const [searchdata, setSearchData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refine, setRefineData] = useState({
    series: true,
    movies: true,
  });

  const fetchSearch = async (q) => {
    try {
      let newdata = [];
      const res = await getRequest(`/search/?q=${q}`);
      // console.log('res --> ', res);
      if (res.status === 200) {
        const data = await res.json();
        // console.log('data --> ', data);
        setSearchData(data);
        // if (!refine["series"] && !refine["movies"]){
        //     setSearchData(data);
        // } else {
        //     data.map((item, index) => {
        //         if (refine['movies']){
        //             if (item.type === "movies") {
        //                 newdata.push(item);
        //             }
        //         }
        //         if (refine["series"]){
        //             if (item.type === "series") {
        //                 newdata.push(item);

        //             }
        //         }
        //         return null
        //     });
        //     setSearchData(newdata);
        // }
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    // setQ(search_text)
    fetchSearch(search_text);
  }, [search_text]);

  // let newData = searchdata;

  ReactGa.initialize("UA-206471971-2");
  ReactGa.pageview(window.location.pathname + window.location.search);

  // useEffect(() => {}, []);
  // console.log("DDDD", searchdata[Object.keys(searchdata)]);

  const refineData = (refineItem) => {
    const updatedItems = searchdata.filter((curElem) => {
      return curElem.category === refineItem;
    });
    setSearchData(updatedItems);
  };

  const togleRefine = (data) => {
    // setRefineData(data);
    setRefineData(data); //context api
    fetchSearch(search_text);
    // setRefineData(search_text); //context
  };
  if (loading) {
    return (
      <Override>
        <Loader type="Bars" color="red" height={70} width={80} />
      </Override>
    );
  }
  return (
    <div>
      <Header focus={true} />
      <div className={searchStyles.header}>
        {search_text ? (
          <p className={searchStyles.title}>
            Results for "<span>{search_text}</span>"
          </p>
        ) : (
          <p className={searchStyles.title}></p>
        )}

        {/* <div className={searchStyles.dropdown}>
					<button className={searchStyles.dropbtn}>Refine</button>
					<div className={searchStyles.dropdown_content}>
						<a>
							Movies
							<input
								onClick={() =>
									togleRefine({ ...refine, "movies": !refine['movies'] })
								}
								className={searchStyles.checkbox1}
								type="checkbox"
                                // {refine.movies && 'checked'}
                                
							/>
						</a>
						<a>
							Series
							<input
								className={searchStyles.checkbox2}
								onClick={() =>
									togleRefine({ ...refine, "series": !refine["series"] })
								}
								// onClick={() => refineData("series")}
								type="checkbox"
							/>
						</a>
					</div>
				</div> */}
      </div>
      {/* <hr className={searchStyles.line} /> */}

      {searchdata.length === 0 ? (
        <h4 style={{ color: "white", textAlign: "center", margin: 80 }}>
          No search result found
        </h4>
      ) : (
        <section class="section product_list">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 product_list_right">
                <div class="product_page_product_list product_details_similar">
                  
                {searchdata.data.map((prod, index) => {
        			  return (
                  <div class="similar_product_list_box">
                    {(prod.threed_model != '' ? 
                      (<div class="short_ar">
                      <a href={ "https://miragesoftwares.com/html/prelito/roomviewer.html?"+prod.threed_model } class="clip-AR-icon clip_ar_div" data-modal="vip-3d-icon-gallery-popup" id="vip-ar-view">
                          <img src="https://ii1.pepperfry.com/images/svg/icon-ar-21.svg" data-pid="1757416" data-lms="lms_clip_ar_view" data-url="https://pepperfry.avataar.me/index.html?skuid=1757416&amp;ar=0" alt="AR Icon" width="24" height="24px" />
                      </a>
                      </div>) 
                      : ('') 
                    )}
                    <div class="short_wishlist">
                      <i class="fa-solid fa-heart"></i>
                    </div>
                    <Link to={`/product-details/${prod.id}`}>
                      <div class="similar_product_list_box_image">
                      <img src={ searchdata.imagePath + prod.product_image}/>
                      </div>
                      <div class="similar_product_list_box_content">
                        <p>{ prod.product_name }</p>
                        <div class="prodcut_price">
                          <span class="current_price"><i class="fa-solid fa-indian-rupee-sign"></i> { prod.special_price }</span>
                          <span class="old_price"><i class="fa-solid fa-indian-rupee-sign"></i> { prod.base_price }</span>
                          <span class="flat_off">{100-(prod.special_price /prod.base_price) *100} %</span>
                        </div>
                        <p>{ prod.labels }</p>
                        <p>Ship in 4 days</p>
                      </div>
                    </Link>
                  </div>
                  );
      					})}

                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
    </div>
  );
};

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

export default Search;
