/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import plansStyles from "../styles/plans.module.css";
import axios from "../utilities/axios";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";
import styled from "styled-components";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { postRequest } from "../utilities/ApiCall";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_HOST_URL } from "../config";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";

// const Cryptr = require('cryptr');
// const cryptr = new Cryptr('my');

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const Pros = (props) => {
    const [loading, setLoading] = useState(true);
    const [products, setProduct] = useState([]);
    const [act_prd, setActProduct] = useState([]);
    const router = useHistory();

    useEffect(async () => {
        setLoading(true);
        const url = `${pxy_url + API_URL}/products`;
        let data = { post_per_page: 10 };

        const response = await fetch(url, {
            method: "POST",
            headers: {
                // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
                "Content-Type": "application/json",
                // "Access-Control-Allow-Origin" : "*",
            },
            body: JSON.stringify(data),
        });

        const responseJson = await response.json();
        console.log(responseJson.data);
        setActProduct(responseJson.data);
        setProduct(responseJson);
        setLoading(false);

        // console.log(act_prd)
    }, []);

    if (loading) {
        return (
            <Override>
                <Loader type="Bars" color="red" height={70} width={70} />
            </Override>
        );
    } else {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Prelito : Professionals</title>
                </Helmet>
                <Header />
                <div class="clearfix"></div>

                <article class="inner_banner">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <h1>1,000 Home design professionals around the world</h1>
                            </div>
                        </div>
                    </div>
                </article>

                <div class="clearfix"></div>

                <section class="section find_pro">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 find_pro_top">
                                <div class="filter_box color_green all_filter">
                                    <a href="javascript:void(0);"><i class="fa fa-filter"></i> All Filters</a>
                                </div>
                                <div class="filter_box color_green">
                                    <a href="javascript:void(0);">Location <i class="fa fa-caret-down"></i></a>
                                </div>
                                <div class="filter_box">
                                    <a href="javascript:void(0);">Professional Category <i class="fa fa-caret-down"></i></a>
                                </div>
                                <div class="filter_box">
                                    <a href="javascript:void(0);">Budget <i class="fa fa-caret-down"></i></a>
                                </div>
                                <div class="filter_box color_green">
                                    <a href="javascript:void(0);">Language <i class="fa fa-caret-down"></i></a>
                                </div>
                                <div class="filter_box color_green">
                                    <a href="javascript:void(0);">Rating <i class="fa fa-caret-down"></i></a>
                                </div>
                                <div class="product_search">
                                    <form>
                                        <input type="text" name="search" placeholder="Search.."/>
                                    </form>
                                </div>
                            </div>
                            <div class="col-lg-12 p-0 product_type_heading">
                                <div class="sort_by">
                                    <p><span><b>1-15</b> of <b>1,30,339</b></span> Professionals</p>
                                </div>
                            </div>
                            <div class="col-lg-12 find_pro_bottom">
                                <div class="col-lg-12 product_listing">
                                    <div class="row">
                                        <div class="col-lg-4 product_listing_image">
                                            <img src={require("../assets/img/bedroom.jpg")}/>
                                        </div>
                                        <div class="col-lg-8 product_listing_rightside">
                                            <div class="product_listing_rightside_content">
                                                <div class="photos_name">
                                                    <div class="photos_name_icon">
                                                    <img src={require("../assets/img/photgrapher.webp")}/>
                                                    </div>
                                                    <div class="product_name_rating">
                                                        <span>ABC Interiorz Pvt.Ltd.</span>
                                                        <div class="product_rating">
                                                            <b>4.9</b>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <span>64 Reviews</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="product_listing_rightside_content_part">
                                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without</p>
                                                    <div class="product_listing_rightside_content_user">
                                                        <h4>-Smitha Ashok</h4>
                                                        <Link to={`/pros-details/1`}>Read More <i class="fa-regular fa-angle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="product_listing_contact">
                                                <div class="send_message">
                                                    <a href="javascript:void(0);"><i class="fa-solid fa-envelope"></i> Send Message</a>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <i class="fa-solid fa-phone"></i> <span>View Phone Number</span>
                                                    </li>
                                                    <li>
                                                        <i class="fa-solid fa-location-dot"></i> <span>Lake Town, Kolkata, 700059</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 product_listing">
                                    <div class="row">
                                        <div class="col-lg-4 product_listing_image">
                                        <img src={require("../assets/img/bedroom.jpg")}/>
                                        </div>
                                        <div class="col-lg-8 product_listing_rightside">
                                            <div class="product_listing_rightside_content">
                                                <div class="photos_name">
                                                    <div class="photos_name_icon">
                                                    <img src={require("../assets/img/photgrapher.webp")}/>
                                                    </div>
                                                    <div class="product_name_rating">
                                                        <span>XYZ Interiorz Pvt.Ltd.</span>
                                                        <div class="product_rating">
                                                            <b>4.9</b>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <span>64 Reviews</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="product_listing_rightside_content_part">
                                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without</p>
                                                    <div class="product_listing_rightside_content_user">
                                                        <h4>-Smitha Ashok</h4>
                                                        <Link to={`/pros-details/1`}>Read More <i class="fa-regular fa-angle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="product_listing_contact">
                                                <div class="send_message">
                                                    <a href="javascript:void(0);"><i class="fa-solid fa-envelope"></i> Send Message</a>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <i class="fa-solid fa-phone"></i> <span>View Phone Number</span>
                                                    </li>
                                                    <li>
                                                        <i class="fa-solid fa-location-dot"></i> <span>Bangalore Urban Karnataka, 560048</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 product_listing">
                                    <div class="row">
                                        <div class="col-lg-4 product_listing_image">
                                        <img src={require("../assets/img/bedroom.jpg")}/>
                                        </div>
                                        <div class="col-lg-8 product_listing_rightside">
                                            <div class="product_listing_rightside_content">
                                                <div class="photos_name">
                                                    <div class="photos_name_icon">
                                                    <img src={require("../assets/img/photgrapher.webp")}/>
                                                    </div>
                                                    <div class="product_name_rating">
                                                        <span>Home Interiorz Pvt.Ltd.</span>
                                                        <div class="product_rating">
                                                            <b>4.9</b>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <span>64 Reviews</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="product_listing_rightside_content_part">
                                                    <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without</p>
                                                    <div class="product_listing_rightside_content_user">
                                                        <h4>-Smitha Ashok</h4>
                                                        <Link to={`/pros-details/1`}>Read More <i class="fa-regular fa-angle-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="product_listing_contact">
                                                <div class="send_message">
                                                    <a href="javascript:void(0);"><i class="fa-solid fa-envelope"></i> Send Message</a>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <i class="fa-solid fa-phone"></i> <span>View Phone Number</span>
                                                    </li>
                                                    <li>
                                                        <i class="fa-solid fa-location-dot"></i> <span>Kolkata, 700080</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="clearfix"></div>

                <Footer />
            </div>
        );
    }
};

export default Pros;
