import React, { useEffect, useState } from "react";
// import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import { api_url_main as API_URL } from "../utilities/constants";
import { useHistory } from "react-router-dom";
import axios from "../utilities/axios";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";
import Swal from "sweetalert2";

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const PrivacyPolicy = () => {
  const [loading, setLoading] = useState(true);
  const [privacy_data, setPrivvacydata] = useState([]);
  const router = useHistory();
  useEffect(async () => {
    // window.scrollTo(0, 0);
    setLoading(true);
    const response = await axios.get(`${API_URL}/privacy_policy`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.data;
    setPrivvacydata(data.data);
    setLoading(false);

    // console.log(data)
  }, []);
  if (loading) {
    return (
      <Override>
        <Loader type="Bars" color="red" height={70} width={70} />
      </Override>
    );
  } else {
  return (
    <div style={{ color: "black" }}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Prelito - Privacy Policy</title>
    </Helmet>
    <Header />
    <div style={styles.content}>
   
      <h3 style={styles.gap}>Privacy Policy</h3>
      <hr style={styles.border} />
      {/* <p>{privacy_data.meta_value}</p> */}
      <div dangerouslySetInnerHTML={{__html: privacy_data.meta_value}} />
    </div>

    <Footer />
  </div>
  );
}
};

const styles = {
  content: {
    margin: "25pt",
  },
  heading: {
    textAlign: "center",
  },
  border: {
    marginTop: "30px",
    borderTop: "1px solid #fff",
  },
  gap: {
    marginTop: "30px",
    marginBottom: "25px",
  },
  ul: {
    listStyleType: "square",
    marginLeft: "27px",
  },
};

export default PrivacyPolicy;
