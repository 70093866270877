/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import plansStyles from "./../styles/plans.module.css";
import ReactGa from "react-ga";
import {
    is_server,
    is_client,
    getToken,
} from "../utilities/auth";
import axios from "../utilities/axios";

import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import { Details, SentimentSatisfied } from "@mui/icons-material";
import detailStyles from "./../styles/billingDetails.module.css";
import { parseCookies, setCookie, destroyCookie } from "nookies";
import { postRequest, getRequest } from "../utilities/ApiCall";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Context from "../context_api/global";
import { RAZORPAY_PUBLIC_KEY, RAZORPAY_SECRET_KEY } from "../config";
import Swal from "sweetalert2";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const Storiesdetails = (props) => {
    const [loading, setLoading] = useState(true);
    const [storiesdetails, setStoriesDetails] = useState([]);
    const [actstories, setActStoriesDetails] = useState([]);
    const [relatedstories, setRelatedStories] = useState([]);
    const [quantity, setQuantity] = useState('');
    const [error, setError] = useState("");
    const router = useHistory();

    useEffect(async () => {
        setLoading(true);
        let paramss = props.match.params;
        // console.log(paramss.storiesdetails);
        let body = { id: paramss.storiesdetails };
        const blogres = await fetch(`${pxy_url + API_URL}/blogdetails`, {
            method: "POST",
            // mode: "no-cors",
            headers: {
              // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
              "Content-Type": "application/json",
              // "Access-Control-Allow-Origin" : "*",
            },
            body: JSON.stringify(body),
          });

        const responseJson = await blogres.json();
        console.log(responseJson);
        setStoriesDetails(responseJson);

        const sresponse = await fetch(`${pxy_url + API_URL}/relatedblogs`, {
            method: "POST",
            headers: {
                // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
                "Content-Type": "application/json",
                // "Access-Control-Allow-Origin" : "*",
            },
            body: JSON.stringify(body),
        });

        const sresponseJson = await sresponse.json();
        // console.log(responseJson);
        setRelatedStories(sresponseJson);
        setLoading(false);

        // console.log(data)
    }, []);
    const handleQtyChange = event => {
        setQuantity(event.target.value);

        // console.log('value is:', event.target.value);
    };
    if (loading) {
        return (
            <Override>
                <Loader type="Bars" color="red" height={70} width={80} />
            </Override>
        );
    } else {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Prelito : Stories Details</title>
                </Helmet>
                <Header />
                <div class="clearfix"></div>
                <section class="section find_pro">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 find_pro_bottom">
                                <div class="row">
                                    <div class="col-lg-8 stories_details_left">
                                        <div class="col-lg-12">
                                            <div class="stories_details_left_top">
                                                <h2>{storiesdetails.data.name}</h2>
                                                <span>More Room Guides, Decorating Ideas</span>
                                                <div class="photos_name">
                                                    <div class="photos_name_icon">
                                                    <img src={require("../assets/img/photgrapher.webp")}/>
                                                    </div>
                                                    <div class="product_name_rating">
                                                        <p>{storiesdetails.data.name}</p>
                                                        <p>{storiesdetails.data.created_at}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="stories_details_left_middle">
                                                <a href="javascript:void(0);"><i class="fa-regular fa-heart"></i> Save</a>
                                                <a href="javascript:void(0);"><i class="fa-regular fa-thumbs-up"></i> like</a>
                                                <a href="javascript:void(0);"><i class="material-icons">chat_bubble_outline</i> Comment</a>
                                            </div>
                                            <div class="stories_details_left_bottom">
                                            {storiesdetails.data.description}
                                            <div class="stories_details_left_bottom_list">
                                                <div class="col-lg-6 find_pro_list_box">
                                                    <div class="find_pro_list_box_image">
                                                    <img src={ storiesdetails.imagePath + storiesdetails.data.image} />
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 stories_details_right">
                                        <div class="col-lg-12 stories_details_right_top">
                                            <h2>You may also like</h2>
                                        </div>
                                        {relatedstories.data.map((type, index) => (
                                        <div class="col-lg-12">
                                            <div class="find_pro_list_box">
                                                <div class="find_pro_list_box_image">
                                                <Link to={`/stories-details/${type.id}`}><img
                                                    src={ relatedstories.imagePath + type.image}
                                                    /></Link>
                                                </div>
                                                <div class="find_pro_list_box_contet">
                                                    <div class="photos_name">
                                                        <div class="photos_name_icon">
                                                        <img src={require("../assets/img/photgrapher.webp")}/>
                                                        </div>
                                                        <div class="product_name_rating">
                                                            <h4>{type.name}</h4>
                                                            {/* <span>Bangalore Urban, Karnataka - 560048</span> */}
                                                            <div class="product_rating">
                                                                <b>4.9</b>
                                                                <i class="fa fa-star checked"></i>
                                                                <i class="fa fa-star checked"></i>
                                                                <i class="fa fa-star checked"></i>
                                                                <i class="fa fa-star checked"></i>
                                                                <i class="fa fa-star checked"></i>
                                                                <span>64 Reviews</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <div class="clearfix"></div>
                <Footer />
            </div>
        );
    }
};

export default Storiesdetails;
