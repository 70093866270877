export const protected_pages = [];

export const protected_pages_for_loggedin_user = [
  "Login",
  "ForgotPassword",
  "ProfileUpdate",
  "Transaction",
  "WatchList",
  "ActivePlan",
  "active-plan",
  "payment",
  "Billing",
  "Plans",
];

// export const api_url = "https://muktiprime.herokuapp.com/v1"
export const api_url = "https://api.muktiprime.com/v1";
export const api_url_main = "https://prelito.miragesoftwares.com/management/public/api";
// export const pxy_url = "https://cors-anywhere.herokuapp.com/";
export const pxy_url = "";
// export const api_url_main = "http://localhost:8000/api"
export const image_base = " https://api.muktiprime.com";
export const RAZORPAY_PUBLIC_KEY = "rzp_test_dV5abIk4odkAmQ";
export const RAZORPAY_SECRET_KEY = "nbHQe9vU7xzZHUh54H05kgbJ";
