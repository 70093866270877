/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import plansStyles from "../styles/plans.module.css";
import axios from "../utilities/axios";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";
import styled from "styled-components";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { postRequest } from "../utilities/ApiCall";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_HOST_URL } from "../config";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";
// import ImgsViewer from "react-images-viewer";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

// const Cryptr = require('cryptr');
// const cryptr = new Cryptr('my');

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const Photosdetails = (props) => {
    const [loading, setLoading] = useState(true);
    const [photos, setPhotos] = useState([]);
    const [photosss, setPhotosss] = useState([]);
    const [products, setProduct] = useState([]);
    const [act_prd, setActProduct] = useState([]);
    const router = useHistory();

    useEffect(async () => {
        setLoading(true);
        let paramss = props.match.params;
        // console.log(paramss.storiesdetails);
        let body = { id: paramss.photosdetails };
        const url = `${pxy_url + API_URL}/photosdisplay`;
        let data = { id: -1 };

        const response = await fetch(url, {
            method: "POST",
            headers: {
                // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
                "Content-Type": "application/json",
                // "Access-Control-Allow-Origin" : "*",
            },
            body: JSON.stringify(body),
        });

        const responseJson = await response.json();
        // console.log(responseJson.data);
        setPhotos(responseJson.data);

        const urls = `${pxy_url + API_URL}/allphotos`;
        let datas = { limit: 4 };

        const responses = await fetch(urls, {
            method: "POST",
            headers: {
                // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
                "Content-Type": "application/json",
                // "Access-Control-Allow-Origin" : "*",
            },
            body: JSON.stringify(datas),
        });

        const rjson = await responses.json();
        // console.log(responseJson.data);
        setPhotosss(rjson);

        const urlp = `${pxy_url + API_URL}/products`;
        let datap = { post_per_page: 4 };

        const responsep = await fetch(urlp, {
        method: "POST",
        headers: {
            // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
            "Content-Type": "application/json",
            // "Access-Control-Allow-Origin" : "*",
        },
        body: JSON.stringify(datap),
        });

        const responseJsonp = await responsep.json();
        setActProduct(responseJsonp.data);
        setProduct(responseJsonp);

        setLoading(false);

        // console.log(act_prd)
        
    }, []);
    //   const images = [
    //     {
    //       original: 'https://prelito.miragesoftwares.com/management/public/uploads/photos/22e1b4a8006be4fb_0442-w360-h360-b0-p0--.webp',
    //       thumbnail: 'https://prelito.miragesoftwares.com/management/public/uploads/photos/22e1b4a8006be4fb_0442-w360-h360-b0-p0--.webp',
    //     }
    //   ];
      

    if (loading) {
        return (
            <Override>
                <Loader type="Bars" color="red" height={70} width={70} />
            </Override>
        );
    } else {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Prelito : Photos</title>
                </Helmet>
                <Header />
                <ImageGallery items={photos} />;
                
            <div class="clearfix"></div>

            <section class="section product_type">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 product_type_heading">
                    <h2>Other Photos in this Project</h2>
                  </div>
                  {photosss.data.map((type, index) => (
                    
                    <div class="col-lg-3 product_part">
                      <div class="product_type_image">
                      <Link to={`/photos-details/${type.id}`}>
                            <img src={ photosss.imagePath + type.image}
                        /></Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            <div class="clearfix"></div>

            <section class="section product_type">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 product_type_heading">
                    <h2>Related Photos</h2>
                  </div>
                  {photosss.data.map((type, index) => (
                    <div class="col-lg-3 product_part">
                      <div class="product_type_image">
                      <Link to={`/photos-details/${type.id}`}>
                            <img src={ photosss.imagePath + type.image}
                        /></Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            <div class="clearfix"></div>

            <section class="section product_type">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 product_type_heading">
                    <h2>Related Products with this photos</h2>
                  </div>
                  <div class="product_page_product_list product_details_similar">
                  
                {act_prd.map((prod, index) => {
        			  return (
                  <div class="similar_product_list_box">
                    {(prod.threed_model != '' ? 
                      (<div class="short_ar">
                      <a href={ "https://miragesoftwares.com/html/prelito/roomviewer.html?"+prod.threed_model } class="clip-AR-icon clip_ar_div" data-modal="vip-3d-icon-gallery-popup" id="vip-ar-view">
                          <img src="https://ii1.pepperfry.com/images/svg/icon-ar-21.svg" data-pid="1757416" data-lms="lms_clip_ar_view" data-url="" alt="AR Icon" width="24" height="24px" />
                      </a>
                      </div>) 
                      : ('') 
                    )}
                    <div class="short_wishlist">
                      <i class="fa-solid fa-heart"></i>
                    </div>
                    <Link to={`/product-details/${prod.id}`}>
                      <div class="similar_product_list_box_image">
                      <img src={ products.imagePath + prod.product_image}/>
                      </div>
                      <div class="similar_product_list_box_content">
                        <p>{ prod.product_name }</p>
                        <div class="prodcut_price">
                          <span class="current_price"><i class="fa-solid fa-indian-rupee-sign"></i> { prod.special_price }</span>
                          <span class="old_price"><i class="fa-solid fa-indian-rupee-sign"></i> { prod.base_price }</span>
                          <span class="flat_off">{100-(prod.special_price /prod.base_price) *100} %</span>
                        </div>
                        <p>{ prod.labels }</p>
                        <p>Ship in 4 days</p>
                      </div>
                    </Link>
                  </div>
                  );
      					})}

                </div>
                </div>
              </div>
            </section>
            <Footer />
            </>
        );
    }
};

export default Photosdetails;
