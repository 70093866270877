/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import plansStyles from "../styles/plans.module.css";
import axios from "../utilities/axios";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";
import styled from "styled-components";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { postRequest } from "../utilities/ApiCall";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_HOST_URL } from "../config";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";

// const Cryptr = require('cryptr');
// const cryptr = new Cryptr('my');

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const Stories = (props) => {
    const [loading, setLoading] = useState(true);
    const [blogs, setBlogs] = useState([]);
    const [act_prd, setActProduct] = useState([]);
    const router = useHistory();

    useEffect(async () => {
        setLoading(true);
        let body = { limit: -1 };

        // Blogs
        const blogres = await fetch(`${pxy_url + API_URL}/blogs`, {
            method: "POST",
            // mode: "no-cors",
            headers: {
              // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
              "Content-Type": "application/json",
              // "Access-Control-Allow-Origin" : "*",
            },
            body: JSON.stringify(body),
          });
        const blogs = await blogres.json();
        setBlogs(await blogs);
        setLoading(false);

        // console.log(act_prd)
    }, []);

    if (loading) {
        return (
            <Override>
                <Loader type="Bars" color="red" height={70} width={70} />
            </Override>
        );
    } else {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Prelito : Stories</title>
                </Helmet>
                <Header />
                <div class="clearfix"></div>

                <article class="inner_banner_image_main">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 inner_banner_image">
                                <div class="col-lg-9 inner_banner_image_heading">
                                    <h1>Get inspired by the stories from professionals.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                <div class="clearfix"></div>

                <section class="section find_pro">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 find_pro_bottom">
                                <div class="row">
                                    {blogs.data.map((blog, index) => (
                                    <div class="col-lg-4">
                                        <div class="find_pro_list_box">
                                            <div class="find_pro_list_box_image">
                                            <Link to={`/stories-details/${blog.id}`}><img src={ blogs.imagePath + blog.image} /></Link>
                                            </div>
                                            <div class="find_pro_list_box_contet">
                                                <div class="photos_name">
                                                    <div class="photos_name_icon">
                                                    <img src={require("../assets/img/photgrapher.webp")}/>
                                                    </div>
                                                    <div class="product_name_rating">
                                                        <h4>{blog.name}</h4>
                                                        {/* <span>Bangalore Urban, Karnataka - 560048</span> */}
                                                        <div class="product_rating">
                                                            <b>4.9</b>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <span>64 Reviews</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="clearfix"></div>

                <Footer />
            </div>
        );
    }
};

export default Stories;
