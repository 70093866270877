/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import plansStyles from "./../styles/plans.module.css";
import ReactGa from "react-ga";
import {
  is_server,
  is_client,
  getToken,
} from "../utilities/auth";
import axios from "../utilities/axios";

import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import { Details, SentimentSatisfied } from "@mui/icons-material";
import detailStyles from "./../styles/billingDetails.module.css";
import { parseCookies, setCookie, destroyCookie } from "nookies";
import { postRequest, getRequest } from "../utilities/ApiCall";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Context from "../context_api/global";
import { RAZORPAY_PUBLIC_KEY, RAZORPAY_SECRET_KEY } from "../config";
import Swal from "sweetalert2";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const Prosdetails = (props) => {
  const [loading, setLoading] = useState(true);
  const [productdetails, setProductDetails] = useState([]);
  const [quantity, setQuantity] = useState('');
  const [error, setError] = useState("");
  const router = useHistory();

  useEffect(async () => {
    setLoading(true);
    // const url = `${pxy_url + API_URL}/product-details`;
    // let paramss = props.match.params;
    // // console.log(paramss.productdetails);
    // let data = { product_id: paramss.productdetails};

    // const response = await fetch(url, {
    //   method: "POST",
    //   headers: {
    //     // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
    //     // "Content-Type": "application/json",
    //     // "Access-Control-Allow-Origin" : "*",
    //   },
    //   body: JSON.stringify(data),
    // });

    // const responseJson = await response.json();
    // console.log(responseJson);
    // setProductDetails(responseJson);
    setLoading(false);

    // console.log(data)
  }, []);
  const handleQtyChange = event => {
    setQuantity(event.target.value);

    // console.log('value is:', event.target.value);
  };
  const addToCart = async () => {
    setLoading(true);
    const url = `${pxy_url + API_URL}/add-to-cart`;
    let paramss = props.match.params;
    let user_id = localStorage.getItem("user");
    // let quantity = this.state.value;
    // console.log(quantity);
    let data = { product_id: paramss.productdetails , user_id:user_id, quantity:1};

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin" : "*",
      },
      body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    // console.log(responseJson);
    if (responseJson.status === 1) {
        
        setLoading(false);
        Swal.fire({
          title: "Product Added To Cart!",
          icon: "success",
          confirmButtonText: "OK!",
        });
        setTimeout(() => {
          setError("");
        }, 2000);
      // Device Binding Checking end
    } else {
        setLoading(false);
      // console.log("logged in failed", response.status, responseJson);

      Swal.fire({
        title: `Error!`,
        text: `Please try after sometime`,
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
    setLoading(false);
}
const buyNow = async () => {
    setLoading(true);
    const url = `${pxy_url + API_URL}/add-to-cart`;
    let paramss = props.match.params;
    let user_id = localStorage.getItem("user");
    // let quantity = this.state.value;
    // console.log(quantity);
    let data = { product_id: paramss.productdetails , user_id:user_id, quantity:1};

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin" : "*",
      },
      body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    // console.log(responseJson);
    if (responseJson.status === 1) {
        
        setLoading(false);
        Swal.fire({
          title: "Product Added To Cart!",
          icon: "success",
          confirmButtonText: "OK!",
        });
        setTimeout(() => {
          setError("");
          router.push("/cart");
        }, 2000);
      // Device Binding Checking end
    } else {
        setLoading(false);
      // console.log("logged in failed", response.status, responseJson);

      Swal.fire({
        title: `Error!`,
        text: `Please try after sometime`,
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
    setLoading(false);
}
  if (loading) {
    return (
      <Override>
        <Loader type="Bars" color="red" height={70} width={80} />
      </Override>
    );
  } else {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Prelito : Pros Details</title>
        </Helmet>
        <Header />
        <div class="clearfix"></div>
	
        <section class="section find_pro_details">
		<div class="container">
			<div class="row">
				<div class="col-lg-8 find_pro_details_left">
					<div class="col-lg-12 find_pro_details_left_banner">
						<div class="find_pro_list_box">
							<div class="find_pro_list_box_image">
								<a href="javascript:void(0);"><img src={require("../assets/img/find-pro-banner.png")}/></a>
							</div>
							<div class="find_pro_list_box_contet">
								<div class="photos_name">
									<div class="photos_name_icon">
                                    <img src={require("../assets/img/bonito.png")}/>
									</div>
									<div class="product_name_rating">
										<h4>ABC Design</h4>
										<span>Lake Town, Kolkata, 700059</span>
										<span>Interior Designers & Interior Decorators</span>
										<div class="product_rating">
											<b>4.9</b>
											<i class="fa fa-star checked"></i>
											<i class="fa fa-star checked"></i>
											<i class="fa fa-star checked"></i>
											<i class="fa fa-star checked"></i>
											<i class="fa fa-star checked"></i>
											<span>64 Reviews</span>
										</div>
									</div>
								</div>
								<div class="share_icon">
                                <img src={require("../assets/img/share.png")}/>
									<span>Share</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-12 find_pro_details_main_tab">
						<div class="find_pro_tab_list">
							<ul>
								<li target="1" class="tab_list_active">About Us</li>
								<li target="2">Projects</li>
								<li target="3">Reviews</li>
								<li target="4">Ideabooks</li>
							</ul>
						</div>
						<div class="find_pro_tab_box">
							<div class="find_pro_tab_box_part hide tab_box_active" id="dis1">
								<div class="row">
									<div class="col-lg-12 find_pro_tab_content">
										<p>Embark on your designer home journey with Bonito Designs. We design your dream home interiors for you & your loved ones. Bengaluru's Most Innovative Interior Design Brand of the year 2021, Now in Mumbai (022 69236813)</p>

										<h4>Services Provided</h4>
										<p>3D Rendering, Basement Design, Bathroom Design, Bedroom Design, Custom Bathroom Vanities, Custom Bookcases, Custom Cabinets, Custom Furniture, Custom Home Bars, Custom Kitchen Cabinets, Custom Shelving, Custom Walk-in Wardrobes, Dining Room Design, Feng Shui Design, Floor Plans, Furniture Selection, Home Office Design & Construction, Home Theater Design, Interior Design, Kids Bedroom Design, Kitchen Design, Laundry /Utility Room Design, Lighting Design, Living Room Design, Playroom Design, Space Planning, Staircase Design, Sustainable Design, Wardrobe Design, Home Office Design, Closet Design, Color Consulting, Custom Pantries, Entry Design, Project Management</p>

										<h4>Areas Served</h4>
										<p>Bangalore, Mumbai</p>

										<h4>Awards</h4>
										<p>- National Architecture and Interior Design Excellence Awards 2020 - “Best and Most Innovative Interior Design Firm of the Year 2020” & 40 under 40 Best Interior Designers of India 2020 - Commonfloor – Best Interior Design Company of the Year 2019 - Asia's Largest Subscribed Youtube Channel</p>
									</div>
									<div class="col-lg-12 find_pro_project_part">
										<div class="row">
											<div class="col-lg-6 product_part">
												<div class="find_pro_project_box">
													<a href="javascript:void(0);">
														<div class="product_type_image">
                                                        <img src={require("../assets/img/find-pro.png")}/>
														</div>
														<div class="product_type_name">
															<h5>Mr. Lokesh's | 2bhk | Prestige Lakeside.. </h5>
															<p>Kolkata, West Bengal</p>                     
															<span>12 photos</span>
														</div>
													</a>
												</div>
											</div>
											<div class="col-lg-6 product_part">
												<div class="find_pro_project_box">
													<a href="javascript:void(0);">
														<div class="product_type_image">
                                                        <img src={require("../assets/img/find-pro.png")}/>
														</div>
														<div class="product_type_name">
															<h5>Mr. Lokesh's | 2bhk | Prestige Lakeside.. </h5>
															<p>Bengaluru, Karnataka</p>                     
															<span>12 photos</span>
														</div>
													</a>
												</div>
											</div>
											<div class="col-lg-6 product_part">
												<div class="find_pro_project_box">
													<a href="javascript:void(0);">
														<div class="product_type_image">
                                                        <img src={require("../assets/img/find-pro.png")}/>
														</div>
														<div class="product_type_name">
															<h5>Mr. Lokesh's | 2bhk | Prestige Lakeside.. </h5>
															<p>Bengaluru, Karnataka</p>                     
															<span>12 photos</span>
														</div>
													</a>
												</div>
											</div>
											<div class="col-lg-6 product_part">
												<div class="find_pro_project_box">
													<a href="javascript:void(0);">
														<div class="product_type_image">
                                                        <img src={require("../assets/img/find-pro.png")}/>
														</div>
														<div class="product_type_name">
															<h5>Mr. Lokesh's | 2bhk | Prestige Lakeside.. </h5>
															<p>Bengaluru, Karnataka</p>                     
															<span>12 photos</span>
														</div>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="find_pro_tab_box_part hide" id="dis2">
								<div class="row">
									<div class="col-lg-12 find_pro_tab2_top">
										<h2>Penthouse | Prestige Shantiniketan | Contemporary Designs | Artis Interiorz</h2>
										<ul>
											<li>Project Cost: ₹1,00,00,001 - ₹1,50,00,000</li>
											<li>Country: India</li>
											<li>PIN code 560067</li>
										</ul>
										<div class="share_icon">
                                        <img src={require("../assets/img/share.png")}/>
											<span>Share</span>
										</div>
									</div>
									<div class="col-lg-12 find_pro_tab_project">
										<div class="row">
											<div class="col-lg-4 tab_project_photo">
												<a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-2.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-3.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-4.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-5.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-6.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-7.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-8.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="find_pro_tab_box_part hide" id="dis3">
								<div class="row">
									<div class="col-lg-12">
										Reviews Content
									</div>
								</div>
							</div>
							<div class="find_pro_tab_box_part hide" id="dis4">
								<div class="row">
									<div class="col-lg-12 find_pro_tab_project">
										<div class="row">
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
											<div class="col-lg-4 tab_project_photo">
                                            <a href="javascript:void(0);"><img src={require("../assets/img/fp-1.png")}/></a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 find_pro_details_right">
					<div class="find_pro_details_right_top">
						<h4>Contact Bonito Design</h4>
						<div class="send_message">
							<a href="javascript:void(0);"> Send Message</a>
						</div>
						<div class="find_pro_details_right_top_box">
							<div class="find_pro_details_list">
								<div class="find_pro_details_list_icon">
                                <img src={require("../assets/img/f-call.png")}/>
								</div>
								<div class="find_pro_details_list_text">
									<span>View phone number</span>
								</div>
							</div>
							<div class="find_pro_details_list">
								<div class="find_pro_details_list_icon">
                                <img src={require("../assets/img/f-web.png")}/>
								</div>
								<div class="find_pro_details_list_text">
									<span>Website</span>
								</div>
							</div>
							<div class="find_pro_details_list">
								<div class="find_pro_details_list_icon">
                                <img src={require("../assets/img/f-location.png")}/>
								</div>
								<div class="find_pro_details_list_text">
									<span>Bangalore Urban Karnataka 560048</span>
								</div>
							</div>
							<div class="find_pro_details_list">
								<div class="find_pro_details_list_icon">
                                <img src={require("../assets/img/f-amount.png")}/>
								</div>
								<div class="find_pro_details_list_text">
									<span>Typical Job Cost ₹20 lakh - ₹2 crore For Turnkey Interior And Exterior Design Solutions.</span>
								</div>
							</div>
							<div class="find_pro_details_list">
								<ul>
									<li><a href="javascript:void(0);"><i class="fa-brands fa-facebook"></i></a></li>
									<li><a href="javascript:void(0);"><i class="fa-brands fa-twitter"></i></a></li>
									<li><a href="javascript:void(0);"><i class="fa-brands fa-linkedin"></i></a></li>
									<li><a href="javascript:void(0);"><i class="fa-regular fa-link"></i></a></li>
								</ul>
							</div>
						</div>
					</div>
					<div class="find_pro_details_right_bottom">
						<div class="product_review_sec">
							<div class="product_review_top">
								<h4>208 Reviews</h4>
								<div class="product_rating_review">
									<div class="product_ratings">
										<i class="fa fa-star checked"></i>
										<i class="fa fa-star checked"></i>
										<i class="fa fa-star checked"></i>
										<i class="fa fa-star checked"></i>
										<i class="fa fa-star"></i>
									</div>
									<div class="product_review_count">
										<h3>4.3</h3>
										<span>208 Reviews</span>
									</div>
								</div>
								<div class="product_rating_quality">
									<ul>
										<li>
											<span>work quality</span>
											<i class="fa-solid fa-minus checked"></i>
											<i class="fa-solid fa-minus checked"></i>
											<i class="fa-solid fa-minus checked"></i>
											<i class="fa-solid fa-minus checked"></i>
											<i class="fa-solid fa-minus"></i>
											<span class="rating_point">4.4</span>
										</li>
										<li>
											<span>Communication</span>
											<i class="fa-solid fa-minus checked"></i>
											<i class="fa-solid fa-minus checked"></i>
											<i class="fa-solid fa-minus checked"></i>
											<i class="fa-solid fa-minus checked"></i>
											<i class="fa-solid fa-minus"></i>
											<span class="rating_point">4.4</span>
										</li>
										<li>
											<span>Value</span>
											<i class="fa-solid fa-minus checked"></i>
											<i class="fa-solid fa-minus checked"></i>
											<i class="fa-solid fa-minus checked"></i>
											<i class="fa-solid fa-minus checked"></i>
											<i class="fa-solid fa-minus"></i>
											<span class="rating_point">4.4</span>
										</li>
									</ul>
								</div>
							</div>
							<div class="write_a_review">
								<a href="javascript:void(0);">Write a review</a>
							</div>
							<div class="product_review_list">
								<div class="product_review_list_part">
									<div class="product_review_list_part_top">
										<div class="photos_name product_review_list_part_user">
											<div class="photos_name_icon">
                                            <img src={require("../assets/img/photgrapher.webp")}/>
											</div>
											<div class="product_name_rating">
												<span>Chatan Yadav</span>
												<div class="product_review_list_part_user_bottom">
													<div class="product_rating">
														<i class="fa fa-star checked"></i>
														<i class="fa fa-star checked"></i>
														<i class="fa fa-star checked"></i>
														<i class="fa fa-star checked"></i>
														<i class="fa fa-star"></i>
													</div>
													<div class="product_review_list_part_user_date">
														<ul>
															<li>
																<i class="fa-light fa-thumbs-up"></i>
																<span>Helpful(5)</span>
															</li>
															<li>25 May 2022</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="product_review_list_part_bottom">
										<p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
										<div class="product_detail_more_details">
											<a href="javascript:void(0);">Read More.</a>
										</div>
									</div>
								</div>
								<div class="product_review_list_part">
									<div class="product_review_list_part_top">
										<div class="photos_name product_review_list_part_user">
											<div class="photos_name_icon">
                                            <img src={require("../assets/img/photgrapher.webp")}/>
											</div>
											<div class="product_name_rating">
												<span>Chatan Yadav</span>
												<div class="product_review_list_part_user_bottom">
													<div class="product_rating">
														<i class="fa fa-star checked"></i>
														<i class="fa fa-star checked"></i>
														<i class="fa fa-star checked"></i>
														<i class="fa fa-star checked"></i>
														<i class="fa fa-star"></i>
													</div>
													<div class="product_review_list_part_user_date">
														<ul>
															<li>
																<i class="fa-light fa-thumbs-up"></i>
																<span>Helpful(5)</span>
															</li>
															<li>25 May 2022</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="product_review_list_part_bottom">
										<p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
										<div class="product_detail_more_details">
											<a href="javascript:void(0);">Read More.</a>
										</div>
									</div>
								</div>
								<div class="product_review_list_part">
									<div class="product_review_list_part_top">
										<div class="photos_name product_review_list_part_user">
											<div class="photos_name_icon">
                                            <img src={require("../assets/img/photgrapher.webp")}/>
											</div>
											<div class="product_name_rating">
												<span>Chatan Yadav</span>
												<div class="product_review_list_part_user_bottom">
													<div class="product_rating">
														<i class="fa fa-star checked"></i>
														<i class="fa fa-star checked"></i>
														<i class="fa fa-star checked"></i>
														<i class="fa fa-star checked"></i>
														<i class="fa fa-star"></i>
													</div>
													<div class="product_review_list_part_user_date">
														<ul>
															<li>
																<i class="fa-light fa-thumbs-up"></i>
																<span>Helpful(5)</span>
															</li>
															<li>25 May 2022</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="product_review_list_part_bottom">
										<p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
										<div class="product_detail_more_details">
											<a href="javascript:void(0);">Read More.</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-12">
					<div class="col-lg-12 find_pro_details_bottom">
						<div class="row">
							<div class="col-lg-12 find_pro_details_bottom_heading">
								<h4>20 Ideabook</h4>
							</div>
							<div class="col-lg-12 find_pro_project_part">
								<div class="row">
									<div class="col-lg-2 product_part">
										<div class="find_pro_project_box">
											<a href="javascript:void(0);">
												<div class="product_type_image">
                                                <img src={require("../assets/img/fpb-1.png")}/>
												</div>
												<div class="product_type_name">
													<h5>Bonito Team</h5>
													<p>6 Ideas</p>
												</div>
											</a>
										</div>
									</div>
									<div class="col-lg-2 product_part">
										<div class="find_pro_project_box">
											<a href="javascript:void(0);">
												<div class="product_type_image">
                                                <img src={require("../assets/img/fpb-2.png")}/>
												</div>
												<div class="product_type_name">
													<h5>Bonito Team</h5>
													<p>6 Ideas</p>
												</div>
											</a>
										</div>
									</div>
									<div class="col-lg-2 product_part">
										<div class="find_pro_project_box">
											<a href="javascript:void(0);">
												<div class="product_type_image">
                                                <img src={require("../assets/img/fpb-3.png")}/>
												</div>
												<div class="product_type_name">
													<h5>Bonito Team</h5>
													<p>6 Ideas</p>
												</div>
											</a>
										</div>
									</div>
									<div class="col-lg-2 product_part">
										<div class="find_pro_project_box">
											<a href="javascript:void(0);">
												<div class="product_type_image">
                                                <img src={require("../assets/img/fpb-4.png")}/>
												</div>
												<div class="product_type_name">
													<h5>Bonito Team</h5>
													<p>6 Ideas</p>
												</div>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

        <div class="clearfix"></div>
        <Footer />
      </div>
    );
  }
};

export default Prosdetails;
