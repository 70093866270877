/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import plansStyles from "./../styles/plans.module.css";
import ReactGa from "react-ga";
import {
  is_logged_in,
  is_server,
  is_client,
  getToken,
} from "../utilities/auth";
import axios from "../utilities/axios";

import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import { Details, SentimentSatisfied } from "@mui/icons-material";
import detailStyles from "./../styles/billingDetails.module.css";
import { parseCookies, setCookie, destroyCookie } from "nookies";
import { postRequest, getRequest } from "../utilities/ApiCall";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Context from "../context_api/global";
import { RAZORPAY_PUBLIC_KEY, RAZORPAY_SECRET_KEY } from "../config";
import Swal from "sweetalert2";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";

const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;

const Productdetails = (props) => {
  const [loading, setLoading] = useState(true);
  const [productdetails, setProductDetails] = useState([]);
  const [products, setProduct] = useState([]);
    const [act_prd, setActProduct] = useState([]);
  const [quantity, setQuantity] = useState('');
  const [error, setError] = useState("");
  const router = useHistory();

  useEffect(async () => {
    setLoading(true);
    const url = `${pxy_url + API_URL}/product-details`;
    let paramss = props.match.params;
    // console.log(paramss.productdetails);
    let data = { product_id: paramss.productdetails};

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json"
        // "Access-Control-Allow-Origin" : "*",
      },
      body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    console.log(responseJson);
    setProductDetails(responseJson);

    const urlp = `${pxy_url + API_URL}/products`;
    let datap = { post_per_page: 4 };

    const responsep = await fetch(urlp, {
    method: "POST",
    headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin" : "*",
    },
    body: JSON.stringify(datap),
    });

    const responseJsonp = await responsep.json();
    setActProduct(responseJsonp.data);
    setProduct(responseJsonp);
    
    setLoading(false);

    // console.log(data)
  }, []);
  const handleQtyChange = event => {
    setQuantity(event.target.value);

    // console.log('value is:', event.target.value);
  };
  const addToCart = async () => {
    setLoading(true);
    const url = `${pxy_url + API_URL}/add-to-cart`;
    let paramss = props.match.params;
    let user_id = localStorage.getItem("user");
    // let quantity = this.state.value;
    // console.log(quantity);
    let data = { product_id: paramss.productdetails , user_id:user_id, quantity:1};

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin" : "*",
      },
      body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    // console.log(responseJson);
    if (responseJson.status === 1) {
        
        setLoading(false);
        Swal.fire({
          title: "Product Added To Cart!",
          icon: "success",
          confirmButtonText: "OK!",
        });
        setTimeout(() => {
          setError("");
        }, 2000);
      // Device Binding Checking end
    } else {
        setLoading(false);
      // console.log("logged in failed", response.status, responseJson);

      Swal.fire({
        title: `Error!`,
        text: `Please try after sometime`,
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
    setLoading(false);
}
const buyNow = async () => {
    setLoading(true);
    const url = `${pxy_url + API_URL}/add-to-cart`;
    let paramss = props.match.params;
    let user_id = localStorage.getItem("user");
    // let quantity = this.state.value;
    // console.log(quantity);
    let data = { product_id: paramss.productdetails , user_id:user_id, quantity:1};

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin" : "*",
      },
      body: JSON.stringify(data),
    });

    const responseJson = await response.json();
    // console.log(responseJson);
    if (responseJson.status === 1) {
        
        setLoading(false);
        Swal.fire({
          title: "Product Added To Cart!",
          icon: "success",
          confirmButtonText: "OK!",
        });
        setTimeout(() => {
          setError("");
          router.push("/cart");
        }, 2000);
      // Device Binding Checking end
    } else {
        setLoading(false);
      // console.log("logged in failed", response.status, responseJson);

      Swal.fire({
        title: `Error!`,
        text: `Please try after sometime`,
        icon: "error",
        confirmButtonText: "Try Again",
      });
    }
    setLoading(false);
}
  if (loading) {
    return (
      <Override>
        <Loader type="Bars" color="red" height={70} width={80} />
      </Override>
    );
  } else {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Prelito : Product Details</title>
        </Helmet>
        <Header />
        <div class="clearfix"></div>
	
        <section class="pro_details_sec">
            <div class="container">
                <div class="row">
                    <div class="inner_page_main_part product_details_product_part">
                        <div class="product_details_product_part_main_left">
                            <div class="product_details_product_part_image">
                            {(productdetails.threed_model != '' ? 
                                    (<a href={ "https://miragesoftwares.com/html/prelito/roomviewer.html?"+productdetails.threed_model } class="clip-AR-icon clip_ar_div short_ar" data-modal="vip-3d-icon-gallery-popup" id="vip-ar-view">
                                        <img src="https://ii1.pepperfry.com/images/svg/icon-ar-21.svg" data-pid="1757416" data-lms="lms_clip_ar_view" data-url="" alt="AR Icon" width="24" height="24px" />
                                    </a>) 
                                    : ('') 
                                    )}
                                <div class="add_wishlist">
                                    <i class="fa-solid fa-heart"></i>
                                </div>
                                <div id="product_slider" class="carousel slide" data-ride="carousel">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                    <img src={ productdetails.imagePath + productdetails.data.product_image} />
                                    </div>
                                    {/* <div class="carousel-item">
                                    <img src="images/pro1-2.png" alt="Arm Chair">
                                    </div>
                                    <div class="carousel-item">
                                    <img src="images/pro1-3.png" alt="Arm Chair">
                                    </div> */}
                                </div>
                                <ul class="carousel-indicators">
                                    <li data-target="#product_slider" data-slide-to="0" class="active"></li>
                                    <li data-target="#product_slider" data-slide-to="1"></li>
                                    <li data-target="#product_slider" data-slide-to="2"></li>
                                </ul>
                                </div>
                            </div>
                            <div class="cart_n_buynow">
                                <button type="button" class="cart_buy_button add_to_cart" onClick={addToCart}>Add to cart</button>
                                <button type="button" class="cart_buy_button buy_now" onClick={buyNow}>Buy now</button>
                            </div>
                        </div>
                        <div class="product_details_product_part_main_right">
                            <div class="product_details_product_part_content">
                                <div class="product_details_product_part_content_left">
                                    <h4>{ productdetails.data.product_name }
                                    </h4>
                                    <div class="prodcut_price">
                                    
                                        <h5> <b class="old-price">&#8360; { productdetails.data.special_price }</b>&nbsp;&nbsp; <b>&#8360; { productdetails.data.special_price }</b> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{ (productdetails.data.base_price*100/productdetails.data.special_price-100).toLocaleString(undefined, {maximumFractionDigits:2}) } % Off</b></h5>
                                    </div>
                                </div>
                                <div class="product_details_product_part_content_right">
                                    <div class="add_wishlist">
                                        <i class="fa-solid fa-heart"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="prodcut_shot_content">
                                <span>Free Shipping</span>
                                <span>Estimated delivery within 2 week</span>
                            </div>
                            <div class="product_color_sec">
                                <label>Options</label>
                                <div class="product_color_list">
                                    <ul>
                                        <li><a href="javascript:void(0);"><img src={ productdetails.imagePath + productdetails.data.product_image} /></a></li>
                                        {/* <li><a href="javascript:void(0);"><img src="images/pro1-1.png"></a></li>
                                        <li><a href="javascript:void(0);"><img src="images/pro1-1.png"></a></li>
                                        <li><a href="javascript:void(0);"><img src="images/pro1-1.png"></a></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div class="prodcut_count">
                                <input type="button" class="count_updown" onClick='decreaseCount(event, this)' value="-" />
                                <input type="text" id="quantity" name="quantity" class="product_quantity_field" value="1" onChange={handleQtyChange}/>
                                <input type="button" class="count_updown" onClick='increaseCount(event, this)' value="+" />
                            </div>
                            <div class="cart_n_buynow">
                                <button type="button" class="cart_buy_button add_to_cart">Add to cart</button>
                                <button type="button" class="cart_buy_button buy_now">Buy now</button>
                            </div>
                            <div class="prodcut_detail">
                                <h4>product details</h4>
                                <ul>
                                    <li>
                                        <span>Brand</span>
                                        <p>{ productdetails.data.brand_name }</p>
                                    </li>
                                    <li>
                                        <span>Category</span>
                                        <p>{ productdetails.data.product_category_name }</p>
                                    </li>
                                    <li>
                                        <span>weight</span>
                                        <p>{ productdetails.data.weight }kg</p>
                                    </li>
                                    <li>
                                        <span>Room Type</span>
                                        <p>Living Room</p>
                                    </li>
                                </ul>
                                <div class="product_detail_more_details">
                                    <a href="javascript:void(0);">More details....</a>
                                </div>
                            </div>
                            <div class="product_review_sec">
                                <div class="product_review_top">
                                    <h4>208 Reviews</h4>
                                    <div class="product_rating_review">
                                        <div class="product_ratings">
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star checked"></i>
                                            <i class="fa fa-star"></i>
                                        </div>
                                        <div class="product_review_count">
                                            <h3>4.3</h3>
                                            <span>208 Reviews</span>
                                        </div>
                                    </div>
                                    <div class="product_rating_quality">
                                        <ul>
                                            <li>
                                                <span>work quality</span>
                                                <i class="fa-solid fa-minus checked"></i>
                                                <i class="fa-solid fa-minus checked"></i>
                                                <i class="fa-solid fa-minus checked"></i>
                                                <i class="fa-solid fa-minus checked"></i>
                                                <i class="fa-solid fa-minus"></i>
                                                <span class="rating_point">4.4</span>
                                            </li>
                                            <li>
                                                <span>Communication</span>
                                                <i class="fa-solid fa-minus checked"></i>
                                                <i class="fa-solid fa-minus checked"></i>
                                                <i class="fa-solid fa-minus checked"></i>
                                                <i class="fa-solid fa-minus checked"></i>
                                                <i class="fa-solid fa-minus"></i>
                                                <span class="rating_point">4.4</span>
                                            </li>
                                            <li>
                                                <span>Value</span>
                                                <i class="fa-solid fa-minus checked"></i>
                                                <i class="fa-solid fa-minus checked"></i>
                                                <i class="fa-solid fa-minus checked"></i>
                                                <i class="fa-solid fa-minus checked"></i>
                                                <i class="fa-solid fa-minus"></i>
                                                <span class="rating_point">4.4</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="write_a_review">
                                    <a href="javascript:void(0);">Write a review</a>
                                </div>
                                <div class="product_review_list">
                                    <div class="product_review_list_part">
                                        <div class="product_review_list_part_top">
                                            <div class="photos_name product_review_list_part_user">
                                                <div class="photos_name_icon">
                                                    <img src={require("../assets/img/photgrapher.webp")} />
                                                </div>
                                                <div class="product_name_rating">
                                                    <span>Chatan Yadav</span>
                                                    <div class="product_review_list_part_user_bottom">
                                                        <div class="product_rating">
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star"></i>
                                                        </div>
                                                        <div class="product_review_list_part_user_date">
                                                            <ul>
                                                                <li>
                                                                    <i class="fa-light fa-thumbs-up"></i>
                                                                    <span>Helpful(5)</span>
                                                                </li>
                                                                <li>25 May 2022</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product_review_list_part_bottom">
                                            <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                                            <div class="product_detail_more_details">
                                                <a href="javascript:void(0);">Read More.</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product_review_list_part">
                                        <div class="product_review_list_part_top">
                                            <div class="photos_name product_review_list_part_user">
                                                <div class="photos_name_icon">
                                                <img src={require("../assets/img/photgrapher.webp")} />
                                                </div>
                                                <div class="product_name_rating">
                                                    <span>Chatan Yadav</span>
                                                    <div class="product_review_list_part_user_bottom">
                                                        <div class="product_rating">
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star"></i>
                                                        </div>
                                                        <div class="product_review_list_part_user_date">
                                                            <ul>
                                                                <li>
                                                                    <i class="fa-light fa-thumbs-up"></i>
                                                                    <span>Helpful(5)</span>
                                                                </li>
                                                                <li>25 May 2022</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product_review_list_part_bottom">
                                            <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                                            <div class="product_detail_more_details">
                                                <a href="javascript:void(0);">Read More.</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product_review_list_part">
                                        <div class="product_review_list_part_top">
                                            <div class="photos_name product_review_list_part_user">
                                                <div class="photos_name_icon">
                                                <img src={require("../assets/img/photgrapher.webp")} />
                                                </div>
                                                <div class="product_name_rating">
                                                    <span>Chatan Yadav</span>
                                                    <div class="product_review_list_part_user_bottom">
                                                        <div class="product_rating">
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star checked"></i>
                                                            <i class="fa fa-star"></i>
                                                        </div>
                                                        <div class="product_review_list_part_user_date">
                                                            <ul>
                                                                <li>
                                                                    <i class="fa-light fa-thumbs-up"></i>
                                                                    <span>Helpful(5)</span>
                                                                </li>
                                                                <li>25 May 2022</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="product_review_list_part_bottom">
                                            <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available.</p>
                                            <div class="product_detail_more_details">
                                                <a href="javascript:void(0);">Read More.</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="clearfix"></div>

            <section class="section product_type">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12 product_type_heading">
                    <h2>Related Products</h2>
                  </div>
                  <div class="product_page_product_list product_details_similar">
                  
                {act_prd.map((prod, index) => {
        			  return (
                  <div class="similar_product_list_box">
                    {(prod.threed_model != '' ? 
                      (<div class="short_ar">
                      <a href={ "https://miragesoftwares.com/html/prelito/roomviewer.html?"+prod.threed_model } class="clip-AR-icon clip_ar_div" data-modal="vip-3d-icon-gallery-popup" id="vip-ar-view">
                          <img src="https://ii1.pepperfry.com/images/svg/icon-ar-21.svg" data-pid="1757416" data-lms="lms_clip_ar_view" data-url="" alt="AR Icon" width="24" height="24px" />
                      </a>
                      </div>) 
                      : ('') 
                    )}
                    <div class="short_wishlist">
                      <i class="fa-solid fa-heart"></i>
                    </div>
                    <Link to={`/product-details/${prod.id}`}>
                      <div class="similar_product_list_box_image">
                      <img src={ products.imagePath + prod.product_image}/>
                      </div>
                      <div class="similar_product_list_box_content">
                        <p>{ prod.product_name }</p>
                        <div class="prodcut_price">
                          <span class="current_price"><i class="fa-solid fa-indian-rupee-sign"></i> { prod.special_price }</span>
                          <span class="old_price"><i class="fa-solid fa-indian-rupee-sign"></i> { prod.base_price }</span>
                          <span class="flat_off">{100-(prod.special_price /prod.base_price) *100} %</span>
                        </div>
                        <p>{ prod.labels }</p>
                        <p>Ship in 4 days</p>
                      </div>
                    </Link>
                  </div>
                  );
      					})}

                </div>
                </div>
              </div>
            </section>

        <div class="clearfix"></div>
        <Footer />
      </div>
    );
  }
};

export default Productdetails;
