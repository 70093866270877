/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import WebStyle from "../Common.css";
import WebResponsiveStyle from "../Commonresponsive.css";
import { pxy_url } from "../utilities/constants";
import { api_url_main as API_URL } from "../utilities/constants";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import styled from "styled-components";
const Override = styled.div`
  position: fixed;
  top: 40%;
  left: 47%;
  @media (max-width: 800px) {
    top: 40%;
    left: 40%;
  }
`;
const ModelViewer = (props) => {
    const [loading, setLoading] = useState(true);
    const [productdetails, setProductDetails] = useState([]);
    const [quantity, setQuantity] = useState('');
    const [error, setError] = useState("");
    const router = useHistory();
  
    useEffect(async () => {
      setLoading(true);
      const url = `${pxy_url + API_URL}/product-details`;
    //   let paramss = props.match.params;
      // console.log(paramss.productdetails);
      let data = { product_id: 1};
  
      const response = await fetch(url, {
        method: "POST",
        headers: {
          // "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept",
          "Content-Type": "application/json"
          // "Access-Control-Allow-Origin" : "*",
        },
        body: JSON.stringify(data),
      });
  
      const responseJson = await response.json();
      console.log(responseJson);
      setProductDetails(responseJson);
      setLoading(false);
  
      // console.log(data)
    }, []);
    const handleQtyChange = event => {
      setQuantity(event.target.value);
  
      // console.log('value is:', event.target.value);
    };
    if (loading) {
      return (
        <Override>
          <Loader type="Bars" color="red" height={70} width={80} />
        </Override>
      );
    } else {
      return (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Prelito : Product Details</title>
          </Helmet>
          <Header />
          <div class="clearfix"></div>
      
          <section class="pro_details_sec">
              <div class="container">
                  <div class="row">
                      <div class="inner_page_main_part product_details_product_part">
                          <div class="product_details_product_part_main_left">
                          <model-viewer src="https://miragesoftwares.com/html/prelito/3d-model-main/sofa_03_4k.gltf" alt="A 3D model of a car" shadow-intensity="1" camera-controls auto-rotate ar >
                        </model-viewer>
                              <div class="cart_n_buynow">
                                  
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
  
          <div class="clearfix"></div>
          <Footer />
        </div>
      );
    }
  };
export default ModelViewer;